import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import {
  responseDomainAccessRequest,
  sendDomainAccessRequest,
} from 'mirage-api-client/dist/identity';
import { base64ToJson } from '../../lib/utils';
import { useStyles } from './styles';
import UnblockInfo from './components/UnblockInfo';
import RequestStatus from './components/RequestStatus';
import UnblockStatus from './components/UnblockStatus';
import SplashScreen from '../../Shell/SplashScreen';
import useAPIClientInit from '../../entities/useAPIClientInit';

const FilterSupportPage = () => {
  useAPIClientInit();
  const classes = useStyles();
  const { data } = useParams();
  const [complete, setComplete] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [failed, setFailed] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [dataJson, setDataJson] = useState(null);

  useEffect(() => {
    const handleData = async value => {
      const json = base64ToJson(value);
      setDataJson(json);
      if (json.status === 'unblock') {
        await handleRequest(
          sendDomainAccessRequest,
          json.identity_id,
          json.tokenUnblock
        );
      } else if (json.status === 'deny') {
        await handleRequest(
          responseDomainAccessRequest,
          json.identity_id,
          json.tokenDeny
        );
      }
    };

    if (data) {
      handleData(data);
    }
  }, [data]);

  const handleRequest = async (requestAction, identityId, token) => {
    try {
      setIsRequesting(true);
      await requestAction(identityId, token);
      setComplete(true);
      setFailed(false);
      setIsRequesting(false);
    } catch (e) {
      setFailed(true);
      setComplete(false);
      setIsRequesting(false);
    }
  };

  const handleSubmitDomainAccessRequest = () => {
    handleRequest(
      responseDomainAccessRequest,
      dataJson.identity_id,
      dataJson.tokenApprove
    );
  };

  const renderContent = () => {
    if (!dataJson) {
      return (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="primary" />
        </Backdrop>
      );
    }
    if (failed) {
      return <RequestStatus title="Sorry, the link has expired." />;
    }
    if (complete) {
      if (dataJson.status === 'unblock') {
        return <RequestStatus title="Your request has been sent!" />;
      }
      if (dataJson.status === 'deny') {
        return <RequestStatus title="You’ve denied access request!" />;
      }
      if (dataJson.status === 'approve' && !cancel) {
        return (
          <UnblockStatus title="Congratulations! You have successfully added the website to the allow list." />
        );
      }
    }
    if (dataJson.status === 'approve') {
      if (cancel) {
        return (
          <UnblockStatus title="Process Cancelled! You'll need to return to request email and click the Approve button again to submit the website unblock information." />
        );
      }
      return (
        <>
          <UnblockInfo
            dataJson={dataJson}
            setCancel={setCancel}
            responseDomainAccessRequest={handleSubmitDomainAccessRequest}
          />
          {isRequesting && (
            <Backdrop className={classes.backdrop} open>
              <CircularProgress color="primary" />
            </Backdrop>
          )}
        </>
      );
    }

    return <SplashScreen />;
  };

  return renderContent();
};

export default FilterSupportPage;
