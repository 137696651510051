import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  logo: {
    width: 185,
    marginBottom: 24,
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '40px',
    textAlign: 'center',
    fontWeight: '500',
    lineHeight: '52px',
    color: '#003F5A',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 622,
    padding: 24,
    gap: `${theme.spacing()}px`,
    borderRadius: 4,
    backgroundColor: '#F9F9F9',
    marginTop: 24,
  },
  heightContainer: {
    maxHeight: 120,
    display: 'flex',
    gap: `${theme.spacing()}px`,
    overflowY: 'scroll',
  },
  row: {
    display: 'flex',
    width: 622,
    justifyContent: 'space-between',
  },
  label: {
    flex: 1,
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    textAlign: 'left',
    color: '#344054',
  },
  text: {
    flex: 1,
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    textAlign: 'left',
    color: '#344054',
  },
  button: {
    marginTop: 24,
  },
  cancelButton: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    textAlign: 'left',
    color: '#E53935',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 8,
    paddingBottom: 8,
  },
  submitButton: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    textAlign: 'left',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 100,
    color: 'white',
    backgroundColor: '#2AADCD',
  },
  wrapper: {
    maxWidth: 778,
  },
  backdrop: {
    flex: 1,
    zIndex: 1,
    background: `rgba(0,0,0,0.6)`,
  },
}));
