import React from 'react';
import { Typography } from '@material-ui/core';
import Column from 'mirage-components/dist/Column';
import logo from '../../../assets/logo.png';
import { useStyles } from '../styles';

const UnblockStatus = ({ title }) => {
  const classes = useStyles();

  return (
    <Column className={classes.root}>
      <img src={logo} alt="Mirage ID" className={classes.logo} />
      <Column className={classes.wrapper}>
        <Typography className={classes.title}>{title}</Typography>
      </Column>
    </Column>
  );
};

export default UnblockStatus;
