import React from 'react';
import {
  Button,
  makeStyles,
  Backdrop,
  CircularProgress,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import IdentityGrid from 'mirage-components/dist/Identity/Grid';
import CreateIdentity from 'mirage-components/dist/Identity/Create';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Row from 'mirage-components/dist/Row';
import Column from 'mirage-components/dist/Column';
import SearchAndSort from 'mirage-components/dist/SearchAndSort';
import {
  useIdentities,
  update,
  create,
  remove,
  clearIdentityCookies,
} from 'mirage-api-client/dist/identity';
import { useUsers, useUser } from 'mirage-api-client/dist/user';
import { useRegions } from 'mirage-api-client/dist/regions';
import { useSnackBar } from 'mirage-components/dist/SnackBar';
import { hasPermission } from 'mirage-api-client/dist';
import config from '../../config';

const mobileBreakpoint = 680;

const useStyles = makeStyles({
  buttonRow: {
    justifyContent: 'space-between',
    marginBottom: 10,
    [`@media (max-width: ${mobileBreakpoint}px)`]: {
      flexDirection: 'column',
    },
  },
  backdrop: {
    flex: 1,
    zIndex: 1,
  },
  noIdentities: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: 80,
  },
  fab: {
    position: 'fixed',
    right: 16,
    bottom: 16,
    zIndex: 100,
  },
  limitReached: {
    marginBottom: 0,
  },
});

const options = [
  { key: 'name', display: 'Name' },
  { key: 'type', display: 'Type' },
  {
    key: 'createdAt',
    display: 'Created At',
  },
  { key: 'lastUsed', display: 'Last Used' },
];

const searchableFields = ['name', 'id', 'region', 'type', 'status'];

export default function Identities() {
  const classes = useStyles();
  const [identities] = useIdentities();
  const [identitiesSorted, setIdentitiesSorted] = React.useState(identities);
  const [user] = useUser();
  const [users] = useUsers();
  const [regions] = useRegions();
  const formattedRegions = React.useMemo(
    () => (regions ? formatRegions(regions) : {}),
    [regions]
  );
  const { newSnackBar } = useSnackBar();
  const desktop = useMediaQuery(`(min-width: ${mobileBreakpoint}px)`);
  const flags = useFlags();

  function handleSort(data) {
    setIdentitiesSorted(data);
  }

  const identityLimitExceeded =
    user && identities && user.identityLimit <= identities.length;

  async function handleUpdate(id, fields) {
    try {
      await update(id, fields);
      newSnackBar({
        type: 'success',
        message: 'Identity Updated Successfully',
      });
    } catch (error) {
      newSnackBar({
        type: 'error',
        message: (error.response && error.response.data.error) || error.message,
        position: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      throw error;
    }
  }

  async function handleCreate(fields) {
    try {
      await create(fields);
    } catch (error) {
      newSnackBar({
        type: 'error',
        message: (error.response && error.response.data.error) || error.message,
        position: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      throw error;
    }
  }

  async function handleDelete(id) {
    try {
      await remove(id);

      newSnackBar({
        type: 'success',
        message: 'Identity Deleted Successfully',
      });
    } catch (error) {
      newSnackBar({
        type: 'error',
        message: (error.response && error.response.data.error) || error.message,
        position: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    }
  }

  async function handleClearCookies(id) {
    try {
      const fields = {
        cookiesClearTimeStamp: Number(new Date()),
      };

      await clearIdentityCookies(id);
      await update(id, fields);
      newSnackBar({
        type: 'success',
        message: 'Cookies Cleared Successfully',
      });
    } catch (error) {
      newSnackBar({
        type: 'error',
        message: (error.response && error.response.data.error) || error.message,
        position: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    }
  }

  if (
    Array.isArray(identities) &&
    regions &&
    user &&
    user.status !== 'suspended'
  ) {
    if (identities.length > 0) {
      return (
        <>
          <Row className={classes.buttonRow}>
            <SearchAndSort
              data={identities}
              options={options}
              defaultValue="name"
              onChange={handleSort}
              searchableFields={searchableFields}
            />
            {hasPermission(user, 'identity.write') && (
              <Column>
                <CreateIdentity
                  regions={formattedRegions}
                  onSubmit={handleCreate}
                  canEditPermissions={
                    hasPermission(user, 'user.write') && flags.accountManagement
                  }
                  canEnableSync={flags.syncingPermission}
                  canEditAllowList={flags.allowList}
                  canEditMeshimer={flags.meshimerWhilelist}
                  canEditPasswordManagement={
                    flags.perUserManagement && user && !user.parentUID
                  }
                  users={users}
                >
                  {desktop ? (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={identityLimitExceeded}
                    >
                      {identityLimitExceeded
                        ? `Max Identities Reached`
                        : `Create Identity`}
                    </Button>
                  ) : (
                    <Fab
                      color="primary"
                      aria-label="create identity"
                      className={classes.fab}
                      disabled={identityLimitExceeded}
                    >
                      <AddIcon />
                    </Fab>
                  )}
                </CreateIdentity>
                {identityLimitExceeded && (
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    className={classes.limitReached}
                  >
                    <a
                      href={`${config.websiteUrl}/contact`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Request an increased identity limit
                    </a>
                  </Typography>
                )}
              </Column>
            )}
          </Row>
          <IdentityGrid
            identities={identitiesSorted || []}
            canEditPermissions={
              hasPermission(user, 'user.write') && flags.accountManagement
            }
            canEditAllowList={flags.allowList}
            canEnableSync={flags.syncingPermission}
            canEditMeshimer={flags.meshimerWhilelist}
            canEditPasswordManagement={
              flags.perUserManagement && user && !user.parentUID
            }
            users={users}
            user={user}
            regions={formattedRegions}
            onSubmit={handleUpdate}
            onDelete={handleDelete}
            onClearCookies={handleClearCookies}
          />
        </>
      );
    }
    return (
      <Column className={classes.noIdentities}>
        <Typography variant="h6" className={classes.noIdentities}>
          You do not have any identities yet.
        </Typography>
        {hasPermission(user, 'identity.write') && (
          <CreateIdentity
            regions={formattedRegions}
            canEditPermissions={
              hasPermission(user, 'user.write') && flags.accountManagement
            }
            canEnableSync={flags.syncingPermission}
            canEditAllowList={flags.allowList}
            canEditMeshimer={flags.meshimerWhilelist}
            canEditPasswordManagement={
              flags.perUserManagement && user && !user.parentUID
            }
            onSubmit={handleCreate}
            users={users}
          >
            <Button variant="contained" color="primary">
              Create Identity
            </Button>
          </CreateIdentity>
        )}
      </Column>
    );
  }
  if (user && user.status === 'suspended') {
    return <></>;
  }

  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress color="primary" />
    </Backdrop>
  );
}

function formatRegions(regions) {
  const isArrayRegions = Array.isArray(regions);
  if (isArrayRegions) {
    return regions.reduce((acc, el) => {
      acc[el.slug] = el.name.substring(0, el.name.length - 2);
      return acc;
    }, {});
  }
  return null;
}
