export function trialDaysRemaining(endTimestamp) {
  if (!endTimestamp) return 0;
  const currentTime = new Date();
  const UTCTime =
    Date.UTC(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      currentTime.getDate()
    ) / 1000;
  const oneDay = 60 * 60 * 24;
  const difference = endTimestamp - UTCTime;
  const remaining = Math.floor(difference / oneDay);
  return remaining > -1 ? remaining : -1;
}

export function isTrialExpired(endTimestamp) {
  return trialDaysRemaining(endTimestamp) < 0;
}

export function base64ToJson(base64String) {
  const json = Buffer.from(base64String, 'base64').toString();
  return JSON.parse(json);
}

export function formatValues(unformatted) {
  if (unformatted) {
    // replace spaces with new lines
    return unformatted.trim().split(' ');
  }
  return [];
}
