import React from 'react';
import { Container, Typography, Button } from '@material-ui/core';
import Column from 'mirage-components/dist/Column';
import Row from 'mirage-components/dist/Row';
import DangerConfirmationModal from 'mirage-components/dist/DangerConfirmationModal';
import logo from '../../../assets/logo.png';
import { formatValues } from '../../../lib/utils';
import { useStyles } from '../styles';

const UnblockInfo = ({ dataJson, setCancel, responseDomainAccessRequest }) => {
  const classes = useStyles();

  const renderItem = (label, value) => (
    <Row className={classes.row}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.text}>{value}</Typography>
    </Row>
  );

  return (
    <Column className={classes.root}>
      <img src={logo} alt="Mirage ID" className={classes.logo} />
      <Typography className={classes.title}>
        Website unblock information
      </Typography>
      <Container className={classes.container}>
        {renderItem('Identity Name:', dataJson.identity_name)}
        {renderItem('MirageId Email:', dataJson.requestor_email)}
        {renderItem('Meshimer Email:', dataJson.email_user_meshimer)}
        {renderItem('Website URL want to unblock:', dataJson.domain)}
      </Container>
      <Container className={classes.container}>
        <Typography className={classes.label}>Allowed Urls</Typography>
        <Column className={classes.heightContainer}>
          {formatValues(dataJson.whiteList).length > 0 ? (
            formatValues(dataJson.whiteList).map((item, index) => (
              <Typography key={index} className={classes.text}>
                {item}
              </Typography>
            ))
          ) : (
            <Typography className={classes.text}>
              No URLs allowed yet
            </Typography>
          )}
        </Column>
      </Container>
      <Row className={`${classes.row} ${classes.button}`}>
        <DangerConfirmationModal
          onClick={() => setCancel(true)}
          actionLabel="CONFIRM"
          cancelBtnLabel="NO"
          title=""
          description="Are you sure you want to cancel the action?"
        >
          <Button className={classes.cancelButton}>CANCEL</Button>
        </DangerConfirmationModal>
        <DangerConfirmationModal
          onClick={responseDomainAccessRequest}
          actionLabel="CONFIRM"
          cancelBtnLabel="NO"
          title=""
          description="Are you sure you want to add this website to allow list?"
        >
          <Button className={classes.submitButton}>SUBMIT</Button>
        </DangerConfirmationModal>
      </Row>
    </Column>
  );
};

export default UnblockInfo;
